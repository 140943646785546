import React from "react";
import {
  Message,
  Header,
  Menu,
  Container,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import logo from "./assets/images/esl-logo-on-white.png";
import "./App.css";

import Certificates from "./components/Certificates";
import Organizers from "./components/Organizers";
import Webhooks from "./components/Webhooks";
import Organizer from "./components/Organizer";
import Season from "./components/Season";
import Tournament from "./components/Tournament";
import ProDBRequest from "./components/ProDBRequest";
import TournamentBundle from "./components/TournamentBundle";
import IFrame from "./components/IFrame";
import Footer from "./components/Footer";
import { useGetVersion } from "./hooks/version";

function App() {
  const history = useHistory();
  const location = useLocation();
  const handleItemClick = (e, { name }) => history.push(`/${name}`);
  const handleLogoutClick = () => (window.location.href = "/logout");
  const { isLoading, data: version, error } = useGetVersion();

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader>Starting up</Loader>
      </Dimmer>
    );
  }

  if (!isLoading && version?.first === false) {
    return (
      <div className="App">
        <Container>
          <Header size="huge" dividing style={{ marginTop: 40 }}>
            Setup
          </Header>
          <Certificates />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="App">
        <Container>
          <Message error style={{ marginTop: 40 }}>
            <Message.Header>Failed to start</Message.Header>
            <p>{error?.message}</p>
          </Message>
        </Container>
      </div>
    );
  }

  return (
    <div className="App">
      <Menu pointing secondary>
        <Menu.Item header>
          <img
            src={logo}
            className="ui"
            height="30"
            width="97"
            alt="esl logo"
          />
        </Menu.Item>
        <>
          <Menu.Item
            name="organizers"
            active={location.pathname === "/organizers"}
            onClick={handleItemClick}
          >
            Standings
          </Menu.Item>
          <Menu.Item
            name="webhooks"
            active={location.pathname === "/webhooks"}
            onClick={handleItemClick}
          >
            Webhooks
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item
              name="prodb"
              active={location.pathname === "/prodb"}
              onClick={handleItemClick}
            >
              ProDB
            </Menu.Item>
            <Menu.Item
              name="auth"
              active={location.pathname === "/auth"}
              onClick={handleItemClick}
            >
              Authorization
            </Menu.Item>
            <Menu.Item
              name="api"
              active={location.pathname === "/api"}
              onClick={handleItemClick}
            >
              API
            </Menu.Item>
            <Menu.Item name="logout" onClick={handleLogoutClick} />
          </Menu.Menu>
        </>
      </Menu>
      <Container>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/organizers" />
          </Route>
          <Route exact path="/organizers">
            <Organizers />
          </Route>
          <Route exact path="/webhooks">
            <Webhooks />
          </Route>
          <Route exact path="/organizers/:orgKey">
            <Organizer />
          </Route>
          <Route exact path="/organizers/:orgKey/:gameKey">
            <Organizer />
          </Route>
          <Route exact path="/season/:seasonKey/game/:gameKey">
            <Season />
          </Route>
          <Route exact path="/tournament/:tournamentKey">
            <Tournament />
          </Route>
          <Route exact path="/tournament-bundle/:bundleID">
            <TournamentBundle />
          </Route>
          <Route path="/prodb">
            <ProDBRequest />
          </Route>
          <Route path="/auth">
            <Certificates />
          </Route>
          <Route path="/api">
            <IFrame src="swagger/index.html" />
          </Route>
        </Switch>
        <Footer />
      </Container>
    </div>
  );
}

export default App;
