import React from "react";
import {
  Modal,
  Form,
  Button,
  Loader,
  Segment,
  Label,
  Icon,
  Popup,
} from "semantic-ui-react";
import { useGetGamerules } from "../hooks/tiebreak";
import { useSetTournamentBundle } from "../hooks/tournamentBundles";

const proposeGroupName = (tournamentName) => {
  const knownRegions = {
    EU: "Europe",
    Europe: "Europe",
    Asia: "Asia",
    CIS: "CIS",
    NA: "North America",
    "North America": "North America",
    Oceania: "Oceania",
    SA: "South America",
    "South America": "South America",
  };

  for (const key of Object.keys(knownRegions)) {
    if (tournamentName.indexOf(key) !== -1) {
      return knownRegions[key];
    }
  }

  return "";
};

export default function TournamentBundleModal({
  open,
  setOpen,
  bundleID,
  item,
  tournaments,
  seasonID,
  gameKey,
}) {
  const { mutate, isLoading, error, isError } = useSetTournamentBundle(
    bundleID,
    seasonID,
    gameKey
  );
  const { data: gamerules } = useGetGamerules(gameKey);
  const [formData, setFormData] = React.useState(item);
  const handleChange =
    (field) =>
    (e, { value }) => {
      const [parent, child] = field.split(".");

        setFormData({
        ...formData, 
        [parent]: {
          ...formData[parent],
          [child]: value 
        }
      });
    }
  const handleChangeCheckbox =
    (field) =>
    (e, { checked }) => {
      const [parent, child] = field.split(".");

        setFormData({
        ...formData, 
        [parent]: {
          ...formData[parent],
          [child]: checked 
        }
      });
    }
  // Automatically try to propose a name if the group dont have a name
  React.useEffect(() => {
    if (
      open &&
      formData === item &&
      !formData?.name &&
      tournaments?.[0]?.tournament?.name
    ) {
      setFormData({
        ...formData,
        name: proposeGroupName(tournaments[0]?.tournament?.name),
      });
    }
  }, [open, item, tournaments, formData]);

  const onSubmit = () => {
    const payload = {
      ...formData,
      tournament_id: tournaments[0]?.tournament?.key,
      tournament_bundle: {
        ...formData.tournament_bundle,
        season_key: seasonID,
        game_rule: {
            id: formData.tournament_bundle?.game_id,
            game_key: gameKey,
        },
      }
    }

    mutate(
      {
        ...payload,
      },
      {
        onSuccess: (data) => {
          setOpen(false);
        },
      }
    );
  };

  const calculationModuleOptions = [
    {
      value: "",
      text: "-",
    },
    ...Object.entries(gamerules || {}).map((r) => ({
      value: r[0],
      text: r[1],
    })),
  ];

  return (
    <Modal open={!!open} onClose={() => setOpen(false)}>
      <Modal.Header>
        {item?.name || "[Unnamed tournament group]"}{" "}
        <small>(tournament group)</small>
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit} id="TournamentBundleForm">
          <Form.Input
            fluid
            label="Group name / Region name"
            placeholder=""
            onChange={handleChange("tournament_bundle.name")}
            value={formData?.tournament_bundle?.name || ""}
          />
          <Form.Select
            fluid
            label="Game Rule" 
            options={calculationModuleOptions}
            placeholder="Game Rule"
            onChange={handleChange("tournament_bundle.game_id")}
            value={formData?.tournament_bundle?.game_id || formData?.tournament_bundle?.game_rule?.id}
          />
          <Segment>
            <Label ribbon color="blue">
              World Ranking
              <Popup
                trigger={<Icon circular size="small" name="help" />}
                content="Optional information. Only for World Ranking events"
              />
            </Label>
            <Form.Group widths="equal">
              <Form.Select
                fluid
                label="Quality"
                options={[
                  { value: "AAA", text: "AAA" },
                  { value: "AA", text: "AA" },
                  { value: "A", text: "A" },
                  { value: "B", text: "B" },
                  { value: "C", text: "C" },
                  { value: "D", text: "D" },
                ]}
                placeholder="World Ranking quality"
                onChange={handleChange("tournament_bundle.wr_quality")}
                value={formData?.tournament_bundle?.wr_quality}
              />
              <Form.Select
                fluid
                label="Size"
                options={[
                  { value: "XXL", text: "XXL" },
                  { value: "XL", text: "XL" },
                  { value: "L", text: "L" },
                  { value: "M", text: "M" },
                  { value: "S", text: "S" },
                ]}
                placeholder="World Ranking size"
                onChange={handleChange("tournament_bundle.wr_size")}
                value={formData?.tournament_bundle?.wr_size}
              />
              <Form.Input
                type="number"
                fluid
                label="Offline/online multiplier (%)"
                min={50}
                max={100}
                step={1}
                onChange={(e) => {
                  handleChange("tournament_bundle.wr_offline_multiplier")(e, {
                    value: parseFloat(e.target.value / 100).toFixed(2) * 1,
                  });
                }}
                value={parseFloat(
                  (formData?.tournament_bundle?.wr_offline_multiplier || 1) * 100
                ).toFixed(0)}
              />
            </Form.Group>
          </Segment>

          <Form.Checkbox
            fluid
            label="Published"
            onChange={handleChangeCheckbox("tournament_bundle.published")}
            checked={formData?.tournament_bundle?.published}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <span style={{ color: "#db2828" }}>
          {error?.response?.data?.error_message || error?.message}
        </span>
        <Button
          type="submit"
          form="TournamentBundleForm"
          color={isError ? "red" : "green"}
          disabled={isLoading}
        >
          {isLoading && <Loader active inline size="mini" />}
          {!isLoading && "Save"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
