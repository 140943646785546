import React from "react";
import { Message, Dimmer, Loader } from "semantic-ui-react";

export default function AxiosLoadingOrError({
  isLoading,
  isForceFetching,
  error,
  data,
}) {
  if (isLoading) {
    return (
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  } else if (isForceFetching) {
    return (
      <Dimmer active>
        <Loader>Fetching new data from ProDB</Loader>
      </Dimmer>
    );
  }

  if (error || data.error) {
    return (
      <Message error>
        <Message.Header>
          {error?.message || "Failed to load data"}
        </Message.Header>
        <p>
          {data?.error ||
            error?.response?.data?.error_message ||
            error?.response?.data?.error ||
            error?.request?.responseURL}
        </p>
      </Message>
    );
  }

  return null;
}
