import { Container } from "semantic-ui-react";
import React from "react";
import ReactJson from "react-json-view";
import { Dropdown, Input, TextArea, Form } from "semantic-ui-react";

import { getToken } from "../modules/authheader/token";

const options = [
  { key: "get", text: "GET", value: "get" },
  { key: "post", text: "POST", value: "post" },
  { key: "put", text: "PUT", value: "put" },
  { key: "patch", text: "PATCH", value: "patch" },
  { key: "delete", text: "DELETE", value: "delete" },
];

export default function IFrame({ src }) {
  const [method, setMethod] = React.useState("get");
  const [path, setPath] = React.useState("");
  const [body, setBody] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [response, setResponse] = React.useState(null);

  const placeholder = "/api/matches";

  const onSubmit = () => {
    setLoading(true);
    setResponse(null);
    setError(null);

    fetch(`${process.env.REACT_APP_API_URL}v1/prodb/request`, {
      method: "post",
      body: JSON.stringify({
        method,
        path,
        body,
      }),
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      mode: "cors",
    })
      .then(async (resp) => {
        const r = {
          headers: Object.fromEntries(resp.headers.entries()),
          status: resp.status,
          rawBody: await resp.text(),
        };

        try {
          r.body = JSON.parse(r.rawBody);
        } catch (err) {
          r.body = null;
          setError(err);
        }

        setResponse(r);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container
      style={{ display: "flex", flexDirection: "column", minHeight: 800 }}
    >
      <h3>Manual request to ProDB</h3>
      <Form>
        <Form.Field>
          <Input
            label={
              <Dropdown
                options={options}
                value={method}
                onChange={(e, { value }) => setMethod(value)}
              />
            }
            placeholder={placeholder}
            value={path}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                e.preventDefault();
                setPath(placeholder);
                return false;
              } //fill input with placeholder on tab
            }}
            onChange={(e, { value }) => {
              setPath(value);
            }}
            action={{
              loading: loading,
              color: "teal",
              content: "Submit",
              onClick: onSubmit,
            }}
          />
        </Form.Field>
        {method !== "get" && method !== "delete" && (
          <Form.Field>
            <label>Body (application/json)</label>
            <TextArea
              placeholder="{}"
              value={body}
              onChange={(e, { value }) => setBody(value)}
            />
          </Form.Field>
        )}
      </Form>

      {error && (
        <>
          <h3 style={{ color: "red" }}>Failure: {error.message}</h3>
        </>
      )}

      {response && (
        <>
          <h3>Response {response.status}</h3>
          <h4>Headers</h4>
          <ReactJson
            theme="pop"
            src={response.headers}
            style={{ padding: "5px" }}
            name={false}
            collapsed={true}
          />
          <h4>Body</h4>
          {response.body !== null && (
            <ReactJson
              theme="pop"
              src={response.body}
              style={{ padding: "5px" }}
              name={false}
              collapsed={1}
            />
          )}
          {response.body === null && (
            <pre
              style={{
                fontFamily: "monospace",
                border: "1px solid rgb(0, 0, 0)",
                position: "relative",
                padding: "5px",
              }}
            >
              {response.rawBody}
            </pre>
          )}
        </>
      )}
    </Container>
  );
}
