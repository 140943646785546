import { useMutation, useQueryClient, useQuery } from "react-query";
import axios from "axios";

export function useGetTournamentBundle(id) {
  const url = `${process.env.REACT_APP_API_URL}v1/tournament-bundles/${id}`;
  const key = ["bundle", id];

  return useQuery(key, () => axios.get(url).then((res) => res.data));
}

export function useSetTournamentBundle(id, season, game) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const url = `${process.env.REACT_APP_API_URL}v1/tournament-bundles/${
        id || data.id
      }`;

      return axios.patch(url, data).then((res) => res.data);
    },
    {
      onSuccess: (resp) => {
        // queryClient.invalidateQueries(["tournaments"]);
        queryClient.invalidateQueries(["season", season, game]);
      },
    }
  );
}

export function useSetTournamentBundles(season, game) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const url = `${process.env.REACT_APP_API_URL}v2/tournament-bundles`;

      return axios.patch(url, data).then((res) => res.data);
    },
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries(["season", season, game]);
      },
    }
  );
}
