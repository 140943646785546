import React from "react";
import { Table, Loader } from "semantic-ui-react";
import AxiosLoadingOrError from "./AxiosLoadingOrError";

export default function Tournament({ standings, squads, squadsLoading }) {
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Pos</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Contestants</Table.HeaderCell>
            <Table.HeaderCell>Group</Table.HeaderCell>
            <Table.HeaderCell>Matches/Left</Table.HeaderCell>
            <Table.HeaderCell>Score</Table.HeaderCell>
            <Table.HeaderCell>W-L</Table.HeaderCell>
            <Table.HeaderCell>Tiebreak</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Array.isArray(standings?.data) &&
            Object.values(standings.data).map((group, index) =>
              group.map((item, index2) => (
                <React.Fragment key={item.position + index2}>
                  <Table.Row>
                    {index2 === 0 && (
                      <Table.Cell rowSpan={group.length}>
                        {item.position}
                      </Table.Cell>
                    )}

                    <Table.Cell style={{ padding: 5 }}>
                      {squadsLoading && <Loader active inline size="mini" />}

                      {!squadsLoading &&
                        (squads?.[item?.tournamentContestant?.squadKey]?.team
                          ?.images?.[0]?.fileName ? (
                          <img
                            src={
                              squads[item.tournamentContestant.squadKey].team
                                .images[0].image.url
                            }
                            height="32"
                            alt="logo"
                          />
                        ) : (
                          "-"
                        ))}
                    </Table.Cell>
                    <Table.Cell>
                      {item.tournamentContestant.name || "TBD"}
                    </Table.Cell>
                    <Table.Cell>{item.group || "-"}</Table.Cell>
                    <Table.Cell>
                      {item?.score?.games || "-"}/
                      {item?.score?.games_left || "-"}
                    </Table.Cell>
                    <Table.Cell>{item?.score?.points || "-"}</Table.Cell>
                    <Table.Cell>
                      {item?.score?.wins || ""}-{item?.score?.losses || ""}
                      {item?.score?.ties ? ` (${item?.score?.ties})` : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {item?.score?.tbwins +
                        item?.score?.tblosses +
                        item?.score?.tbties >
                      0 ? (
                        <>
                          {item?.score?.tbwins || "0"}-
                          {item?.score?.tblosses || "0"}
                        </>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))
            )}
        </Table.Body>
      </Table>
      <AxiosLoadingOrError
        isLoading={standings.isLoading}
        error={standings.error}
        data={standings.data}
      />
    </>
  );
}
