import { useForceableQuery } from "./helpers";

export function useGetTournament(tournamentKey) {
  const url = `${process.env.REACT_APP_API_URL}v1/tournaments/${tournamentKey}`;
  const key = ["tournaments", tournamentKey];

  return useForceableQuery(key, url);
}

export function useGetTournamentSquads(tournamentKey) {
  const url = `${process.env.REACT_APP_API_URL}v1/tournaments/${tournamentKey}/squads`;
  const key = ["tournament", tournamentKey, "squads"];

  return useForceableQuery(key, url);
}
