import React from "react";
import { Modal } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useGetUnifiedStandings } from "../hooks/standings";
import { useGetTournamentBundle } from "../hooks/tournamentBundles";
import ReactJson from "react-json-view";

import AxiosLoadingOrError from "./AxiosLoadingOrError";
import StandingsTable from "./StandingsTable";

export default function TournamentBundle() {
  const { bundleID } = useParams();
  const [matchModal, setMatchModal] = React.useState(false);
  const standings = useGetUnifiedStandings(bundleID);
  const tournamentBundle = useGetTournamentBundle(bundleID);

  if (tournamentBundle.isLoading || tournamentBundle.isError) {
    <AxiosLoadingOrError {...tournamentBundle} />;
  }

  const tg = tournamentBundle?.data?.tournamentBundle || {};
  const squads = tournamentBundle?.data?.squads;

  return (
    <>
      <Modal open={matchModal !== false} onClose={() => setMatchModal(false)}>
        <Modal.Header>
          {matchModal?.contestants
            ?.map((a) => a.tournamentContestant.name)
            .join(" - ")}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <ReactJson src={matchModal} collapsed={1} />
          </Modal.Description>
        </Modal.Content>
      </Modal>

      {tournamentBundle.isLoaded && (
        <>
          <h1>{tg?.name || "-"}</h1>
          <div
            className={
              "ui image label large " + (tg.published ? "blue" : "gray")
            }
          >
            Public ID
            <div className="detail">{tg?.public_id}</div>
          </div>
        </>
      )}

      <AxiosLoadingOrError {...tournamentBundle} />

      <h2>Standings</h2>
      <StandingsTable
        standings={standings}
        squads={squads}
        squadsLoading={tournamentBundle.isLoading}
      />
      <AxiosLoadingOrError
        isLoading={standings.isLoading}
        error={standings.error}
        data={standings.data}
      />
    </>
  );
}
