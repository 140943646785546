import { Container } from "semantic-ui-react";

export default function IFrame({ src }) {
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", minHeight: 800 }}
    >
      <div>
        <a
          href={`${process.env.REACT_APP_API_URL}${src}`}
        >{`${process.env.REACT_APP_API_URL}${src}`}</a>
      </div>
      <iframe
        src={`${process.env.REACT_APP_API_URL}${src}`}
        title="-"
        style={{ flex: 1 }}
      />
    </Container>
  );
}
