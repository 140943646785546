import React from "react";
import {
  Table,
  Checkbox,
  Segment,
  Ref,
  Button,
  Icon,
  Popup,
} from "semantic-ui-react";
import { useHistory, useParams, Link } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import groupBy from "lodash.groupby";
import find from "lodash.find";
import cuid from "cuid";
import update from "immutability-helper";
import Moment from "react-moment";

import { useGetSeason } from "../hooks/seasons";
import { useSetTournamentBundles } from "../hooks/tournamentBundles";
import DraggableIcon from "../assets/images/draggable.svg";

import AxiosLoadingOrError from "./AxiosLoadingOrError";
import RefetchButton from "./RefetchButton";
import TournamentBundleModal from "./TournamentBundleModal";

const UNBUNDLED = "[unbundled]";

export default function GlobalSeason({ type }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Season type={type} />
    </DndProvider>
  );
}

function Season({ type }) {
  const { seasonKey, gameKey } = useParams();
  const { isLoading, error, data, isForceFetching, force, forceError } =
    useGetSeason(seasonKey, gameKey);

  if (isLoading || error || data.error || isForceFetching) {
    return (
      <AxiosLoadingOrError
        isLoading={isLoading}
        isForceFetching={isForceFetching}
        error={error}
        data={data}
      />
    );
  }

  // This sort is just to keep the order of the bundles the same each time
  const tournamentList =
    data?.tournaments &&
    Object.values(data.tournaments)
      .sort((a, b) => a.tournament.name.localeCompare(b.tournament.name))
      .map((item) => ({
        //change this
        ...item,
        prev_published: item.tournament.published,
        prev_tournament_bundle_id: item.tournament.tournament_bundle_id,
        prev_tournament_bundle_order: item.tournament.tournament_bundle_order,
      }));

  // Group all tournaments by the bundle id
  const tournaments = groupBy(tournamentList, ({ tournament }) => {
    const bundle = find(data?.bundles, { tournament_id: tournament.key });
    if (bundle) {
      return bundle.tournament_bundle?.id;
    } else {
      return UNBUNDLED;
    }
  });
  const bundles = data?.bundles.reduce(
    (acc, bundle) => ({
      ...acc,
      [bundle.tournament_bundle?.id]: bundle,
    }),
    []
  );

  // Sort each tournament bundle by order, then name
  Object.keys(tournaments).forEach((bundleID) => {
    tournaments[bundleID] = tournaments[bundleID].sort(
      (
        a,
        b //change this
      ) =>
        a?.tournament?.tournament_bundle_order -
          b?.tournament?.tournament_bundle_order ||
        a?.tournament?.name.localeCompare(b?.tournament?.name)
    );
  });

  return (
    <>
      <RefetchButton
        isLoading={isLoading}
        isForceFetching={isForceFetching}
        force={force}
        forceError={forceError}
      />

      <h1>
        {data?.season?.competition?.name || ""} - {data?.season?.name || ""}
      </h1>
      <CreateBundleDropzone>
        <small>Drag a stage here to create a new Standings bundle</small>
      </CreateBundleDropzone>
      <TournamentBundles bundles={bundles} tournaments={tournaments} />
    </>
  );
}

function TournamentBundles({ tournaments, bundles }) {
  const { seasonKey, gameKey } = useParams();
  const [items, setItems] = React.useState(tournaments);
  const [dragID, setDragID] = React.useState(null);
  const bundlesMutation = useSetTournamentBundles();
  const [bundleModalID, setBundleModalID] = React.useState(false);

  let modified = false;

  // Make sure each bundle always is sorted by order and then name
  Object.keys(items).forEach((bundleID) => {
    // Assign order
    items[bundleID] = items[bundleID].map((row, index) => {
      row.tournament.tournament_bundle_order = index + 1;

      row.modified = //change this
        row.prev_published !== row.tournament.published ||
        row.prev_tournament_bundle_id !== row.tournament.tournament_bundle_id ||
        row.prev_tournament_bundle_order !==
          row.tournament.tournament_bundle_order;

      if (row.modified) {
        modified = true;
      }

      return row;
    });

    // Sort by order, then name
    items[bundleID] = items[bundleID].sort(
      (a, b) =>
        a?.tournament?.tournament_bundle_order -
          b?.tournament?.tournament_bundle_order ||
        a?.tournament?.name.localeCompare(b?.tournament?.name)
    );
  });

  // Find a specific row id and return row and current index
  const findRow = React.useCallback(
    (id) => {
      const row = Object.keys(items)
        .map((g) => {
          const i = items[g].find((c) => c?.tournament?.key === id);
          if (i) {
            i.tournament.tournament_bundle_id = g;
          }
          return i;
        })
        .find(Boolean);

      return {
        row,
        index: items[row?.tournament?.tournament_bundle_id].indexOf(row),
        bundleID: row?.tournament?.tournament_bundle_id,
      };
    },
    [items]
  );

  // Move a specific row id to any bundle and index
  const moveRow = React.useCallback(
    (id, toIndex, toBundle, newBundle) => {
      const { row, index: fromIndex, bundleID: fromBundle } = findRow(id);

      let newItems = {
        ...items,
        [toBundle]: items[toBundle] || [], // Make sure the new bundle actually exist in our list
      };

      if (fromBundle === toBundle) {
        // Move within the same bundle
        newItems = update(newItems, {
          [fromBundle]: {
            $splice: [
              [fromIndex, 1],
              [toIndex, 0, row],
            ],
          },
        });
      } else {
        // Move between two bundles
        newItems = update(newItems, {
          [fromBundle]: {
            $splice: [[fromIndex, 1]],
          },
          [toBundle]: {
            $splice: [[toIndex, 0, row]],
          },
        });

        // update the sorting indexes
        newItems[toBundle].map((row, index) => {
          //change this
          if (
            row.prev_tournament_bundle_order === undefined &&
            row.tournament.tournament_bundle_order !== index + 1
          ) {
            // Save the previous order
            row.prev_tournament_bundle_order =
              row.tournament.tournament_bundle_order;
          }
          if (
            row.prev_tournament_bundle_id === undefined &&
            row.tournament.tournament_bundle_id !== toBundle
          ) {
            // Save the previous bundle
            row.prev_tournament_bundle_id = row.tournament.tournament_bundle_id;
          }
          row.tournament.tournament_bundle_order = index + 1;
          return row;
        });
      }

      // update the sorting indexes
      newItems[fromBundle].map((row, index) => {
        //change this
        if (
          row.prev_tournament_bundle_order === undefined &&
          row.tournament.tournament_bundle_order !== index + 1
        ) {
          // Save the previous order
          row.prev_tournament_bundle_order =
            row.tournament.tournament_bundle_order;
        }
        if (
          row.prev_tournament_bundle_id === undefined &&
          row.tournament.tournament_bundle_id !== fromBundle
        ) {
          // Save the previous bundle
          row.prev_tournament_bundle_id = row.tournament.tournament_bundle_id;
        }

        row.tournament.tournament_bundle_order = index + 1;
        return row;
      });

      setItems(newItems);
      if (newBundle) {
        setBundleModalID(toBundle);
      }
    },
    [findRow, items, setItems]
  );

  const setPublished = React.useCallback(
    (tournament_key, published) => {
      const newItems = { ...items };

      Object.keys(newItems).forEach((bundle) => {
        newItems[bundle].map((item) => {
          if (item.tournament.key === tournament_key) {
            item.tournament.published = published;
          }
          return item;
        });
      });

      setItems(newItems);
    },
    [items, setItems]
  );

  const onSave = React.useCallback(() => {
    let bundleMap = [];
    Object.values(bundles).forEach((bundle) => {
      const bundleID = bundle?.tournament_bundle?.id;
      if (bundleID === UNBUNDLED) {
        //never send unbundled to the server
        return;
      }

      return items[bundleID]
        .sort(
          (a, b) =>
            new Date(a?.tournament?.time) - new Date(b?.tournament?.time)
        )
        .forEach((item) => {
          bundleMap.push({
            ...bundle,
            tournament_id: item?.tournament?.key,
            tournament_start_time: item?.tournament?.time,
          });
        });
    }, []);

    bundlesMutation.mutate(bundleMap, {
      onSuccess: (data) => {
        const newItems = items;

        // Remove the unsaved flag from each row, because now its successfully saved
        Object.keys(items).forEach((bundleID) => {
          newItems[bundleID] = newItems[bundleID].map((row, index) => {
            return {
              //change this
              ...row,
              prev_published: row.tournament.published,
              prev_tournament_bundle_id: row.tournament.tournament_bundle_id,
              prev_tournament_bundle_order:
                row.tournament.tournament_bundle_order,
            };
          });
        });

        setItems(newItems);
      },
    });
  }, [items, bundlesMutation, bundles]);

  return (
    <>
      {bundleModalID && (
        <TournamentBundleModal
          open={bundleModalID}
          setOpen={setBundleModalID}
          bundleID={bundleModalID}
          item={bundles[bundleModalID]}
          tournaments={items[bundleModalID]}
          seasonID={seasonKey}
          gameKey={gameKey}
        />
      )}
      <>
        <Button
          onClick={onSave}
          floated="right"
          color="green"
          disabled={!modified}
        >
          Save changes
        </Button>
        <div style={{ clear: "both" }} />
      </>
      {Object.entries(items)
        // The following sort will keep the unbundled events at the end
        .sort(([firstBundleId], [secondBundleId]) => {
          if (firstBundleId === "") return 1;
          if (secondBundleId === "") return -1;
          return 0;
        })
        .map(([bundleID, tournaments]) => {
          return (
            <React.Fragment key={bundleID}>
              <>
                {bundleID !== "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <h2 style={{ marginBottom: 0 }}>
                        <Link to={`/tournament-bundle/${bundleID}`}>
                          {bundles[bundleID]?.tournament_bundle?.name &&
                            !bundles[bundleID]?.tournament_bundle?.gamerule && (
                              <Popup
                                content="Gamerule is NOT selected"
                                inverted
                                trigger={
                                  <Icon
                                    name="warning"
                                    style={{ color: "#F2711C" }}
                                  />
                                }
                              />
                            )}
                          {bundles[bundleID]?.tournament_bundle?.name ||
                            "[Unnamed tournament bundle]"}
                        </Link>
                      </h2>

                      {bundles[bundleID]?.tournament_bundle?.public_id && (
                        <div
                          className={
                            "ui image label " +
                            (bundles[bundleID]?.tournament_bundle?.published
                              ? "blue"
                              : "gray")
                          }
                          style={{ whiteSpace: "nowrap", marginLeft: "12px" }}
                        >
                          Public ID
                          <div className="detail">
                            {bundles[bundleID]?.tournament_bundle?.public_id}
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <a
                        href={`/v1/standings/${bundleID}/unified`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Standings
                      </a>
                      <Button
                        animated="vertical"
                        onClick={() => setBundleModalID(bundleID)}
                      >
                        <Button.Content hidden>Settings</Button.Content>
                        <Button.Content visible>
                          <Icon name="settings" />
                        </Button.Content>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <h2>[Default tournament bundle]</h2>
                )}

                <TournamentTable
                  tournamentList={tournaments}
                  bundleID={bundleID}
                  dragID={dragID}
                  findRow={findRow}
                  moveRow={moveRow}
                  onDrag={setDragID}
                  setPublished={setPublished}
                />
              </>
            </React.Fragment>
          );
        })}
    </>
  );
}

function TournamentTable({
  tournamentList,
  bundleID,
  dragID,
  findRow,
  moveRow,
  setPublished,
  onDrag,
}) {
  const history = useHistory();
  const handleRowClick = (key) => (e) => history.push(`/tournament/${key}`);

  const [, drop] = useDrop(
    () => ({
      accept: "tournament",
      drop: () => ({
        bundleID,
        order: 0,
      }),
      hover({ id: draggedId, bundleID: draggedBundleId }) {
        moveRow(
          draggedId, // Row to move
          0, // Index to replace
          bundleID // BundleID of the target
        );
      },
    }),
    [findRow, moveRow]
  );

  tournamentList.sort(
    (t1, t2) => new Date(t2.tournament?.time) - new Date(t1.tournament?.time)
  );

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>Stage</Table.HeaderCell>
          <Table.HeaderCell>Variant</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Matches</Table.HeaderCell>
          <Table.HeaderCell>Published</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tournamentList.length === 0 ? (
          <Ref innerRef={drop}>
            <Table.Row
              style={{
                cursor: "pointer",
              }}
              role="Box"
            >
              <Table.Cell colSpan="7" />
            </Table.Row>
          </Ref>
        ) : null}
        {tournamentList.map(
          (item) =>
            item?.tournament && (
              <DraggableTableRow
                key={item.tournament.key}
                item={item}
                dragID={dragID}
                findRow={findRow}
                moveRow={moveRow}
                onDrag={onDrag}
                modified={item.modified}
              >
                <Table.Cell
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleRowClick(item.tournament.key)}
                >
                  {item?.tournament?.status === "live" && (
                    <div className="ui red horizontal label">Live</div>
                  )}
                  {item?.tournament?.status === "open" && (
                    <div className="ui teal horizontal label">Open</div>
                  )}
                  {item?.tournament?.status === "finished" && (
                    <div className="ui gray horizontal label">Finished</div>
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{ padding: 0 }}
                  onClick={handleRowClick(item.tournament.key)}
                >
                  {item.images?.length && item.images[0].image.url ? (
                    <img src={item.images[0].image.url} height="64" alt="" />
                  ) : (
                    <div style={{ height: 64 }} />
                  )}
                </Table.Cell>
                <Table.Cell onClick={handleRowClick(item.tournament.key)}>
                  <div>{item.tournament.name || "-"}</div>
                  <small>
                    <Moment fromNow withTitle>
                      {item.tournament.time}
                    </Moment>
                  </small>
                </Table.Cell>
                <Table.Cell onClick={handleRowClick(item.tournament.key)}>
                  {item.tournament?.tournamentVariant?.label || "-"}
                </Table.Cell>
                <Table.Cell onClick={handleRowClick(item.tournament.key)}>
                  {item.tournament?.tournamentType?.groupStage && (
                    <div className="ui teal horizontal label">Group stage</div>
                  )}
                  {item.tournament?.tournamentType?.singleElimination && (
                    <div className="ui orange horizontal label">
                      Single elimination
                    </div>
                  )}
                  {item.tournament?.tournamentType?.doubleElimination && (
                    <div className="ui red horizontal label">
                      Double elimination
                    </div>
                  )}
                  {!item.tournament?.tournamentType?.groupStage &&
                    !item.tournament?.tournamentType?.singleElimination &&
                    !item.tournament?.tournamentType?.doubleElimination && (
                      <span className="ui purple horizontal label">
                        {item.tournament?.tournamentType?.label || "-"}
                      </span>
                    )}
                </Table.Cell>
                <Table.Cell onClick={handleRowClick(item.tournament.key)}>
                  {item?.matches?.length || "-"}
                </Table.Cell>
                <Table.Cell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      toggle
                      onChange={(event, { checked }) => {
                        setPublished(item.tournament.key, checked);
                      }}
                      checked={item.tournament.published}
                    />

                    <div
                      className={
                        "ui image label " +
                        (item.prev_published ? "blue" : "gray")
                      }
                      style={{ whiteSpace: "nowrap", marginTop: "12px" }}
                    >
                      Public ID
                      <div className="detail">
                        {item?.tournament?.public_id}
                      </div>
                    </div>
                  </div>
                </Table.Cell>
              </DraggableTableRow>
            )
        )}
      </Table.Body>
    </Table>
  );
}

export const DraggableTableRow = function DraggableTableRow({
  children,
  item,
  dragID,
  moveRow,
  findRow,
  onDrag,
  modified,
}) {
  const outerRef = React.useRef(null);
  const ref = React.useRef(null);
  const id = item.tournament.key;
  const { index: originalIndex, bundleID: originalBundleID } = findRow(id);

  const [, drag, preview] = useDrag(
    () => ({
      type: "tournament",
      item: () => {
        // isDragging does not work when moving between bundles, so we keep that state manually by writing the id to a shared state with onDrag
        onDrag(id);

        return { id, originalIndex, originalBundleID };
      },
      end: (item, monitor) => {
        // isDragging does not work when moving between bundles, so we keep that state manually by writing the id to a shared state with onDrag
        onDrag(null);
        const { id: draggedID, originalIndex, originalBundleID } = item;

        if (monitor.didDrop()) {
          // Dropped! Trigger the onDrop callback to save the new order
          const { id: droppedID, bundleID: droppedBundleID } =
            monitor.getDropResult();

          if (droppedBundleID === "" && droppedID !== draggedID) {
            // Create a new bundle
            moveRow(
              draggedID, // Row to move
              0, // Index to replace
              cuid(), // BundleID of the target,
              true // Signal that its a new bundle
            );
          }
        } else {
          // Did not drop on a droppable area, restore it to the original position again
          moveRow(
            draggedID, // Row to move
            originalIndex, // Index to replace
            originalBundleID // BundleID of the target
          );
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, originalIndex, moveRow]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "tournament",
      drop: () => ({
        //change this
        bundleID: item?.tournament?.tournament_bundle_id,
        order: item?.tournament?.tournament_bundle_order,
        id,
      }),
      hover({ id: draggedId, bundleID: draggedBundleId }) {
        // If we not hover over our selvs
        if (draggedId !== id) {
          const { index: overIndex, bundleID } = findRow(id);
          moveRow(
            draggedId, // Row to move
            overIndex, // Index to replace
            bundleID // BundleID of the target
          );
        }
      },
    }),
    [findRow, moveRow]
  );

  // isDragging does not work when moving between bundles, so we keep that state manually by writing the id to a shared state with onDrag
  const opacity = dragID === id ? 0.4 : 1;

  preview(drop(outerRef));
  drag(ref);

  return (
    <Ref innerRef={outerRef}>
      <Table.Row
        style={{
          cursor: "pointer",
          opacity,
          background: modified ? "rgba(255,255,0, 0.3)" : undefined,
        }}
        role="Box"
      >
        <Ref innerRef={ref}>
          <Table.Cell style={{ cursor: "move" }}>
            <img src={DraggableIcon} alt="drag handle" />
          </Table.Cell>
        </Ref>
        {children}
      </Table.Row>
    </Ref>
  );
};

export const CreateBundleDropzone = ({ children }) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "tournament",
      drop: () => ({
        bundleID: "",
        order: 0,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const isActive = canDrop && isOver;

  return (
    <Ref innerRef={drop}>
      <Segment color={isActive ? "yellow" : "grey"} raised={canDrop}>
        {children}
      </Segment>
    </Ref>
  );
};
