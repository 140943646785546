import { useQuery } from "react-query";
import axios from "axios";

export function useGetCertificates() {
  return useQuery("certificates", () =>
    axios
      .get(`${process.env.REACT_APP_API_URL}v1/certificates`, {
        withCredentials: true,
      })
      .then((res) => res.data)
  );
}
