import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "semantic-ui-less/semantic.less";
import { setupApiAuthenticationInterceptor } from "./modules/authheader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (!error?.response) {
          // The error was not a network error, no need to try again
          return false;
        }

        if (error?.response?.status >= 400 && error?.response?.status <= 499) {
          // The error was not serverside, no need to try again
          return false;
        }

        if (error?.response?.status === 500) {
          // The error was serverside, but its a permanent one
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

// Request interceptors for API calls
setupApiAuthenticationInterceptor();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
