import React from "react";
import {
  Message,
  Table,
  Dimmer,
  Loader,
  Header,
  Icon,
  Modal,
  Button,
} from "semantic-ui-react";
//import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { withTheme } from "@rjsf/core";
import { Theme as SemanticUITheme } from "@rjsf/semantic-ui";

import { useGetCertificates } from "../hooks/certificates";
import { useGetVersion } from "../hooks/version";

const Form = withTheme(SemanticUITheme);

const schema = {
  type: "object",
  required: ["email"],
  properties: {
    email: {
      type: "string",
      title: "Email address to the trusted user",
    },
    password: {
      type: "string",
      title: "Password for the certificate bundle file",
      format: "password",
    },
  },
};

const uiSchema = {};

export default function Certificates({ type }) {
  const { isLoading, error, data } = useGetCertificates();
  const { data: version } = useGetVersion();
  //const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [firstCert, setFirstCert] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [createError, setCreateError] = React.useState(null);
  const formRef = React.createRef();
  const queryClient = useQueryClient();

  //const handleRowClick = key => e => history.push(`/auth/${key}`);
  const onSubmit = ({ formData }) => {
    setDisabled(true);

    fetch(`${process.env.REACT_APP_API_URL}v1/certificates`, {
      method: "post",
      body: JSON.stringify(formData),
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
      .then(async (res) => {
        const blob = await res.blob();
        var file = window.URL.createObjectURL(blob);
        //window.location.assign(file);
        var a = document.createElement("a");
        a.href = file;
        a.download = "filename.p12";
        document.body.appendChild(a);
        a.click();
        a.remove();
        /*var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(blob)
        );
        element.setAttribute("download", res.headers.get("content-filename"));

        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);*/

        setShowModal(false);
        setFirstCert(true);
        await queryClient.refetchQueries(["version"], { active: true });

        return res;
      })
      .catch((err) => {
        setCreateError(err);
      })
      .finally(() => setDisabled(false));
  };

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  }

  if (firstCert) {
    return (
      <Message information>
        <Message.Header>Certificate created</Message.Header>
        <p>
          Great, now install this new certificate in your browser and then
          reload this page
        </p>
      </Message>
    );
  }

  if (error) {
    return (
      <Message error>
        <Message.Header>Failed to load data</Message.Header>
        <p>{error?.message}</p>
      </Message>
    );
  }

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>Issue a new certificate</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {createError && (
              <Message error>
                <Message.Header>Failed to load data</Message.Header>
                <p>{createError?.message}</p>
              </Message>
            )}

            <Form
              schema={schema}
              uiSchema={uiSchema}
              onSubmit={onSubmit}
              formData={formData}
              disabled={disabled}
              onChange={({ formData }) => setFormData(formData)}
            >
              <button type="submit" style={{ display: "none" }} ref={formRef} />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              formRef.current.click();
            }}
            primary
            disabled={disabled}
          >
            Proceed <Icon name="chevron right" />
          </Button>
        </Modal.Actions>
      </Modal>

      {!version?.first && (
        <Message information>
          <Message.Header>First setup</Message.Header>
          <p>
            This is the first time you start the server. Issue a new certificate
            for youself and install it in your browser. Then reload this page.
          </p>
        </Message>
      )}

      <Header as="h2">
        <button
          className="ui right floated button teal"
          onClick={() => setShowModal(true)}
        >
          Issue a new certificate
        </button>
        <Icon name="key" />
        <Header.Content>
          Certificates
          <Header.Subheader>
            Used to authorize clients to access the admin console
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Serial</Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data &&
            data
              .sort((a, b) => a.serial - b.serial)
              .map((item) => (
                <Table.Row key={item.serial} style={{ cursor: "pointer" }}>
                  <Table.Cell>{item.emails || item.name}</Table.Cell>
                  <Table.Cell>
                    {item.type === "ca" && (
                      <div className="ui red horizontal label">CA</div>
                    )}
                    {item.type === "server" && (
                      <div className="ui gray horizontal label">Server</div>
                    )}
                    {item.type === "client" && (
                      <div className="ui teal horizontal label">Client</div>
                    )}
                  </Table.Cell>
                  <Table.Cell>{item.serial || "-"}</Table.Cell>
                  <Table.Cell>{item.expires || "-"}</Table.Cell>
                  <Table.Cell>
                    {item.type === "client" && (
                      <button className="ui button orange" disabled>
                        Revoke
                      </button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table>
    </>
  );
}
