import React from "react";
import { Modal } from "semantic-ui-react";
import { useGetSquad } from "../hooks/squads";
import ReactJson from "react-json-view";

import RefetchButton from "./RefetchButton";

export default function SquadModal({ key, setKey, contestants }) {
  const { data, isLoading, isForceFetching, force, forceError } =
    useGetSquad(key);

  const playerRoles = contestants?.reduce((acc, contestant) => {
    return {
      ...acc,
      ...contestant?.players.reduce(
        (acc2, player) => ({
          ...acc2,
          [player.playerKey]: player?.gamePlayerRole,
        }),
        {}
      ),
    };
  }, {});

  const roleColors = {
    Player: "#090",
    Substitute: "#880",
    Coach: "#900",
  };

  return (
    <Modal open={!!key} onClose={() => setKey(false)}>
      <Modal.Header>
        <RefetchButton
          isLoading={isLoading}
          isForceFetching={isForceFetching}
          force={force}
          forceError={forceError}
          style={{
            marginTop: -5,
            marginRight: -10,
          }}
        />
        {data?.team?.team?.name || key}
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <div>Location: {data?.team?.team?.location || "-"}</div>
          <div>Short name: {data?.team?.team?.shortName}</div>
          <h3>Images</h3>
          {data?.team?.images?.map((image) => (
            <div
              style={{
                background: "#eeeeee",
                display: "inline-block",
                margin: 8,
              }}
            >
              <img height="96" src={image.image.url} alt="" />
            </div>
          ))}

          <h3>Squad</h3>
          <div>Name: {data?.squad?.name || "-"}</div>
          <div>Location: {data?.squad?.location || "-"}</div>

          {data?.players
            ?.sort(
              (a, b) =>
                !!a?.squad.validTo - !!b?.squad.validTo ||
                (playerRoles?.[a?.player?.key]?.order || 99) -
                  (playerRoles?.[b?.player?.key]?.order || 99) ||
                a.player?.nick?.localeCompare(b.player?.nick)
            )
            .map((player) => (
              <div
                style={{
                  background: "#eeeeee",
                  display: "inline-block",
                  margin: 8,
                  width: 96,
                  height: 96,
                  opacity: player?.squad?.validTo ? 0.3 : 1,
                  position: "relative",
                  textAlign: "center",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "20px",
                    background: "#000",
                    opacity: 0.5,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  {player?.player?.nick || "-"}
                </div>
                {player?.images?.[0]?.image?.url && (
                  <img
                    height="96"
                    src={player?.images?.[0]?.image?.url}
                    alt=""
                  />
                )}

                {playerRoles?.[player?.player?.key] &&
                  !player?.squad?.validTo && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "16px",
                          background:
                            roleColors[
                              playerRoles?.[player?.player?.key]?.name
                            ] || "#000",
                          opacity: 0.5,
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        {playerRoles?.[player?.player?.key]?.name || "-"}
                      </div>
                    </>
                  )}
              </div>
            ))}

          {data?.squad?.images?.map((image) => (
            <div
              style={{
                background: "#eeeeee",
                display: "inline-block",
                margin: 8,
              }}
            >
              <img height="96" src={image.image.url} alt="" />
            </div>
          ))}

          <hr style={{ marginTop: 24 }} />
          <ReactJson src={data} collapsed={2} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
