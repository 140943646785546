import { useQuery } from "react-query";
import axios from "axios";

export function useGetVersion() {
  return useQuery(
    "version",
    () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}v1/version`, {
          withCredentials: true,
        })
        .then((res) => res.data),
    {
      staleTime: 300000, // 5 min (300s)
      retry: false,
    }
  );
}
