import axios from "axios";
import { getToken } from "./token";

export function registerAxiosAuthenticationInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Access-Control-Allow-Origin"] = `*`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
