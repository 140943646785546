let storedToken = "";

export function getToken() {
  storedToken = localStorage.getItem("token");
  if (storedToken === "" || storedToken === null) {
    storedToken = askForToken();
    alert(
      "This authentication method is implemented temporary. Delete token from localstorage via browser dev tools"
    );
    //store the token in browser localstorage to avoid providing it everytime
    localStorage.setItem("token", storedToken);
  }

  return storedToken;
}

function askForToken() {
  return prompt("Please enter your token to access this website:");
}
