import React from "react";
import { Button, Icon, Label, Transition } from "semantic-ui-react";

export default function RefetchButton({
  isLoading,
  isForceFetching,
  force,
  forceError,
  style,
}) {
  return (
    <div style={{ float: "right", ...style }}>
      <Transition
        visible={forceError || false}
        animation="flash"
        duration={500}
      >
        <Label as="a" basic color="red" pointing="right">
          Failed
        </Label>
      </Transition>
      <Button
        loading={isForceFetching}
        disabled={!force || isForceFetching}
        icon
        labelPosition="left"
        onClick={() => force()}
      >
        <Icon name="refresh" />
        Refetch from ProDB
      </Button>
    </div>
  );
}
