import React from "react";
import { Table, Modal, Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useGetStandings } from "../hooks/standings";
import { useGetTournament } from "../hooks/tournaments";
import Moment from "react-moment";
import moment from "moment";
import ReactJson from "react-json-view";

import AxiosLoadingOrError from "./AxiosLoadingOrError";
import RefetchButton from "./RefetchButton";
import SquadModal from "./SquadModal";

export default function Tournament({ type }) {
  const { tournamentKey } = useParams();
  const [matchModal, setMatchModal] = React.useState(false);
  const [squadModal, setSquadModal] = React.useState(false);
  const standings = useGetStandings(tournamentKey);
  const tournament = useGetTournament(tournamentKey);

  if (tournament.isLoading || tournament.isError) {
    <AxiosLoadingOrError error={tournament.error} {...tournament} />;
  }

  const t = tournament?.data?.tournament || {};

  return (
    <>
      <Modal open={matchModal !== false} onClose={() => setMatchModal(false)}>
        <Modal.Header>
          {matchModal?.contestants
            ?.map((a) => a.tournamentContestant.name)
            .join(" vs ")}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <ReactJson src={matchModal} collapsed={1} />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <SquadModal
        key={squadModal}
        setKey={setSquadModal}
        contestants={tournament?.data?.contestants}
      />

      <RefetchButton
        isLoading={tournament.isLoading}
        isForceFetching={tournament.isForceFetching}
        force={tournament.force}
        forceError={tournament.forceError}
      />
      <h1>
        {tournament.isLoading ? (
          <Loader active inline size="mini" />
        ) : (
          t?.name || "-"
        )}
      </h1>
      <div
        className={"ui image label large " + (t.published ? "blue" : "gray")}
      >
        Public ID
        <div className="detail">{t?.public_id}</div>
      </div>
      {t?.tournamentType?.groupStage && (
        <div className="ui teal label large">Group stage</div>
      )}
      {t?.tournamentType?.singleElimination && (
        <div className="ui orange label large">Single elimination</div>
      )}
      {t?.tournamentType?.doubleElimination && (
        <div className="ui red label large">Double elimination</div>
      )}
      {!t?.tournamentType?.groupStage &&
        !t?.tournamentType?.singleElimination &&
        !t?.tournamentType?.doubleElimination && (
          <span className="ui purple horizontal label">
            {t?.tournamentType?.label || "-"}
          </span>
        )}

      <h2>Standings</h2>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Pos</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Contestants</Table.HeaderCell>
            <Table.HeaderCell>Group</Table.HeaderCell>
            <Table.HeaderCell>Matches/Left</Table.HeaderCell>
            <Table.HeaderCell>Score</Table.HeaderCell>
            <Table.HeaderCell>W-L</Table.HeaderCell>
            <Table.HeaderCell>Tiebreak</Table.HeaderCell>
            <Table.HeaderCell>Games</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {standings?.data &&
            Object.values(standings.data)
              .sort((a, b) => a.verticalPosition - b.verticalPosition)
              .map((group, index) =>
                group.map((item, index2) => (
                  <React.Fragment key={item.position + index2}>
                    <Table.Row>
                      {index2 === 0 && (
                        <Table.Cell rowSpan={group.length}>
                          {item.position}
                        </Table.Cell>
                      )}

                      <Table.Cell
                        style={{ padding: 5 }}
                        onClick={() =>
                          item?.tournamentContestant?.squadKey &&
                          setSquadModal(item?.tournamentContestant?.squadKey)
                        }
                      >
                        -
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          item?.tournamentContestant?.squadKey &&
                          setSquadModal(item?.tournamentContestant?.squadKey)
                        }
                      >
                        {item.tournamentContestant.name || "TBD"}
                      </Table.Cell>
                      <Table.Cell>{item.group || "-"}</Table.Cell>
                      <Table.Cell>
                        {item?.score?.games || "-"}/
                        {item?.score?.games_left || "-"}
                      </Table.Cell>
                      <Table.Cell>{item?.score?.points || "-"}</Table.Cell>
                      <Table.Cell>
                        {item?.score?.wins || "0"}-{item?.score?.losses || "0"}
                        {item?.score?.ties ? ` (${item?.score?.ties})` : ""}
                      </Table.Cell>
                      <Table.Cell>
                        {item?.score?.tbwins +
                          item?.score?.tblosses +
                          item?.score?.tbties >
                        0 ? (
                          <>
                            {item?.score?.tbwins || "0"}-
                            {item?.score?.tblosses || "0"}
                          </>
                        ) : null}
                      </Table.Cell>
                      <Table.Cell>
                        {item.games_won || "-"}/{item.games_lost || "-"}
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))
              )}
        </Table.Body>
      </Table>
      <AxiosLoadingOrError
        isLoading={standings.isLoading}
        error={standings.error}
        data={standings.data}
      />

      <h2>Matches</h2>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Score</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>Contestants</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Winner</Table.HeaderCell>
            <Table.HeaderCell>Looser</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tournament.data?.matches &&
            Object.values(tournament.data.matches)
              .sort(
                (a, b) =>
                  // a.verticalPosition - b.verticalPosition ||
                  moment(a.match.startTime).diff(moment(b.match.startTime)) ||
                  a.contestants?.[0]?.tournamentContestant.name.localeCompare(
                    b.contestants?.[0]?.tournamentContestant.name
                  )
              )
              .map((item) => (
                <React.Fragment key={item.match.key}>
                  <Table.Row
                    style={{
                      cursor: "pointer",
                      color: item.finished ? "rgba(40,40,40,.3)" : "",
                    }}
                    onClick={() => setMatchModal(item)}
                  >
                    <Table.Cell>
                      {item?.contestants?.map((a) => a?.score).join(" - ")}
                    </Table.Cell>
                    <Table.Cell>{item.match.status}</Table.Cell>
                    <Table.Cell>
                      <Moment fromNow withTitle>
                        {item.match.startTime}
                      </Moment>
                    </Table.Cell>
                    <Table.Cell>
                      {item?.contestants?.length > 0 &&
                        item.contestants
                          .map((a) => (
                            <>
                              {a.tournamentContestant.name}{" "}
                              <small
                                style={{
                                  color:
                                    a?.gameResult?.key === "win"
                                      ? "#090"
                                      : a?.gameResult?.key === "lose"
                                      ? "#900"
                                      : "#999",
                                }}
                              >
                                {a?.gameResult?.key}
                              </small>
                            </>
                          ))
                          .reduce((prev, curr) => [prev, " - ", curr])}
                    </Table.Cell>
                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                      {item?.metaDataMappings?.find(
                        (meta) => meta.metaData.matchMetaDataType === "BRACKET"
                      )?.metaData.name ||
                        item?.metaDataMappings?.find(
                          (meta) => meta.metaData.name === "Regular"
                        )?.metaData.name ||
                        item?.metaDataMappings?.find(
                          (meta) => meta.metaData.name === "Gamerule"
                        )?.metaData.name ||
                        "-"}
                      <br />
                      {item?.metaDataMappings?.find(
                        (meta) => meta.metaData.matchMetaDataType === "ROUND"
                      )?.metaData.name || ""}
                    </Table.Cell>
                    <Table.Cell>
                      {item?.postdecessors?.find(
                        (a) => a.predecessorResult === "winner"
                      ) ? (
                        <small style={{ color: "#999" }}>(proceeds)</small>
                      ) : (
                        item?.contestants?.find(
                          (a) =>
                            a.gameResult.key === "win" ||
                            a.gameResult.key === "defaultwin"
                        )?.tournamentContestant.name || (
                          <small style={{ color: "#999" }}>(TBD)</small>
                        )
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item?.postdecessors?.find(
                        (a) => a.predecessorResult === "loser"
                      ) ? (
                        <small style={{ color: "#999" }}>(proceeds)</small>
                      ) : (
                        item?.contestants?.find(
                          (a) =>
                            a.gameResult.key === "lose" ||
                            a.gameResult.key === "defaultloss"
                        )?.tournamentContestant.name || (
                          <small style={{ color: "#999" }}>(TBD)</small>
                        )
                      )}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
        </Table.Body>
      </Table>
    </>
  );
}
