import React from "react";
import { Table, Label } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Moment from "react-moment";
import ReactJson from "react-json-view";

import AxiosLoadingOrError from "./AxiosLoadingOrError";
import { useGetWebhooks } from "../hooks/webhooks";

function JsonOrText({ item }) {
  const body = atob(item.body);
  try {
    return (
      <ReactJson
        theme="pop"
        src={JSON.parse(body)}
        style={{ padding: "5px" }}
        name={false}
        collapsed
      />
    );
  } catch (e) {
    return <pre>{body}</pre>;
  }
}

export default function Webhooks({ type }) {
  const { isLoading, error, data } = useGetWebhooks();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const handleRowClick = (key, game_id) => (e) =>
    game_id
      ? history.push(`/organizers/${key}/${game_id}`)
      : history.push(`/organizers/${key}`);

  if (isLoading || error || data.error) {
    return (
      <AxiosLoadingOrError isLoading={isLoading} error={error} data={data} />
    );
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Webhooks</h1>
        <Label.Group circular style={{ marginLeft: "0.5em" }}>
          <Label as="a">{data?.length}</Label>
        </Label.Group>
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Response status</Table.HeaderCell>
            <Table.HeaderCell>Body</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data &&
            data
              .sort((a, b) => -moment(a.created_at).diff(moment(b.created_at)))
              .map((item) => (
                <>
                  <Table.Row key={item.ID}>
                    <Table.Cell verticalAlign="top">
                      <Moment fromNow>{item.created_at}</Moment>
                    </Table.Cell>
                    <Table.Cell verticalAlign="top">
                      {item.response_status}
                    </Table.Cell>
                    <Table.Cell verticalAlign="top">
                      <JsonOrText item={item} />
                    </Table.Cell>
                  </Table.Row>
                </>
              ))}
        </Table.Body>
      </Table>
    </>
  );
}
