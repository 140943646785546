import React, { Fragment } from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import { useGetOrganizers } from "../hooks/organizers";
import RefetchButton from "./RefetchButton";
import AxiosLoadingOrError from "./AxiosLoadingOrError";

export default function Organizers({ type }) {
  const { isLoading, error, data, isForceFetching, force, forceError } =
    useGetOrganizers();
  const [showAll, setShowAll] = React.useState(false);
  const history = useHistory();
  const handleRowClick = (orgKey, gameKey) => (e) => {
    gameKey
      ? history.push(`/organizers/${orgKey}/${gameKey}`)
      : history.push(`/organizers/${orgKey}`);
  };

  if (isLoading || error || data.error) {
    return (
      <AxiosLoadingOrError isLoading={isLoading} error={error} data={data} />
    );
  }

  return (
    <>
      <RefetchButton
        isLoading={isLoading}
        isForceFetching={isForceFetching}
        force={force}
        forceError={forceError}
      />
      <h1>Organizers</h1>
      <Checkbox
        toggle
        onChange={(event, { checked }) => {
          setShowAll(checked);
        }}
        checked={showAll}
        label="Show all"
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Game</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data &&
            data
              .filter(
                (item) =>
                  item.type === type && (showAll || item.count_tournaments > 0)
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <Fragment key={item.key}>
                  <Table.Row
                    style={{
                      color:
                        item.count_tournaments > 0 ? "" : "rgba(40,40,40,.3)",
                    }}
                  >
                    <Table.Cell
                      verticalAlign="top"
                      onClick={handleRowClick(item.key)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </Table.Cell>
                    <Table.Cell
                      verticalAlign="top"
                      onClick={handleRowClick(item.key)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {item.count_live > 0 && (
                        <div className="ui red horizontal label">Live</div>
                      )}
                      {item.count_open > 0 && (
                        <div className="ui teal horizontal label">Open</div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item.games
                        ? Object.entries(item.games)
                            .sort(([, a], [, b]) => a.localeCompare(b))
                            .map(([gameKey, game]) => (
                              <div
                                key={gameKey + item.key}
                                style={{ cursor: "pointer" }}
                                onClick={handleRowClick(item.key, gameKey)}
                              >
                                {game}
                              </div>
                            ))
                        : "-"}
                    </Table.Cell>
                  </Table.Row>
                </Fragment>
              ))}
        </Table.Body>
      </Table>
    </>
  );
}
