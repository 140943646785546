import React from "react";
import { useGetVersion } from "../hooks/version";

export default function Footer() {
  const { data: version } = useGetVersion();

  return (
    <div style={{ textAlign: "center" }}>
      <small>
        {version?.version} ({version?.source_ip})
      </small>
    </div>
  );
}
