import React from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

export function useForceableQuery(key, url, opts = {}) {
  const options = {
    valid: true,
    ...opts,
  };
  const [isForceFetching, setForceIsFetching] = React.useState(false);
  const [forceError, setForceError] = React.useState(null);
  const queryClient = useQueryClient();

  const query = useQuery(
    key,
    () => {
      if (!options.valid) {
        return false;
      }

      return axios.get(url).then((res) => res.data);
    },
    { refetchOnWindowFocus: false }
  );

  return {
    ...query,
    isFetching: query.isFetching || isForceFetching,
    isForceFetching,
    forceError,
    force: () => {
      setForceError(null);
      setForceIsFetching(true);
      axios
        .get(`${url}`, {
          withCredentials: true,
          params: { force: true }
        })
        .then((res) => res.data)
        .then((res) => {
          queryClient.invalidateQueries();
          return queryClient.setQueryData(key, res);
        })
        .catch((err) => setForceError(err))
        .finally(() => setForceIsFetching(false));
    },
  };
}
