import React from "react";
import { Table } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { useGetOrganizer } from "../hooks/organizers";
import RefetchButton from "./RefetchButton";
import AxiosLoadingOrError from "./AxiosLoadingOrError";

export default function Organizer({ type }) {
  const { orgKey, gameKey } = useParams();
  const { isLoading, error, data, isForceFetching, force, forceError } =
    useGetOrganizer(orgKey, gameKey);
  const history = useHistory();
  const handleRowClick = (orgKey, gameKey) => (e) =>
    history.push(`/season/${orgKey}/game/${gameKey}`);

  if (isLoading || error || data.error) {
    return (
      <AxiosLoadingOrError isLoading={isLoading} error={error} data={data} />
    );
  }

  const bySeason =
    data &&
    data.tournaments?.reduce((acc, item) => {
      if (gameKey && item.gamePlatform.gameVersion.gameKey !== gameKey) {
        return acc;
      }

      const key = item.seasonKey + item.gamePlatformKey;

      return {
        ...acc,
        [key]: {
          ...item.season,
          gamePlatform: item.gamePlatform,
          count_open:
            (acc[key]?.count_open || 0) + (item.status === "open" ? 1 : 0),
          count_live:
            (acc[key]?.count_live || 0) + (item.status === "live" ? 1 : 0),
          finished:
            item.status !== "finished"
              ? false
              : acc[key]?.finished === undefined
              ? true
              : acc[key]?.finished,
          tournaments: [...(acc[key]?.tournaments || []), item],
        },
      };
    }, {});

  return (
    <>
      <RefetchButton
        isLoading={isLoading}
        isForceFetching={isForceFetching}
        force={force}
        forceError={forceError}
      />
      <h1>{data.name}</h1>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Competition</Table.HeaderCell>
            <Table.HeaderCell>Season</Table.HeaderCell>
            <Table.HeaderCell>Dates</Table.HeaderCell>
            <Table.HeaderCell>Game</Table.HeaderCell>
            <Table.HeaderCell>Tournaments</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {bySeason &&
            Object.values(bySeason)
              .sort((a, b) => -moment(a.end).diff(b.end))
              .map((item, key) => (
                <React.Fragment
                  key={key + item.gamePlatform?.gameVersion?.game?.key}
                >
                  <Table.Row
                    onClick={handleRowClick(
                      item.key,
                      item.gamePlatform?.gameVersion?.game?.key
                    )}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Table.Cell>{item?.competition?.name || "-"}</Table.Cell>
                    <Table.Cell>{item?.name || "-"}</Table.Cell>
                    <Table.Cell>
                      {item.start || "-"}
                      {item.end ? ` - ${item.end}` : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {item.gamePlatform?.gameVersion?.game?.name}{" "}
                      <small>({item.gamePlatform?.platform?.label})</small>
                    </Table.Cell>
                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                      <span style={{ marginRight: "1em" }}>
                        {item.tournaments.length}
                      </span>
                      {item.count_live > 0 && (
                        <div className="ui red horizontal label">Live</div>
                      )}
                      {item.count_open > 0 && (
                        <div className="ui teal horizontal label">Open</div>
                      )}
                      {item.finished && (
                        <div className="ui horizontal label">Finished</div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
        </Table.Body>
      </Table>
    </>
  );
}
