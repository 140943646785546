import { useForceableQuery } from "./helpers";

export function useGetStandings(tournamentKey) {
  const url = `${process.env.REACT_APP_API_URL}v1/standings/tournament/${tournamentKey}`;
  const key = ["standings", tournamentKey];

  return useForceableQuery(key, url);
}

export function useGetUnifiedStandings(bundleID) {
  const url = `${process.env.REACT_APP_API_URL}v1/standings/bundle/${bundleID}`;
  const key = ["standings", bundleID];

  return useForceableQuery(key, url);
}
