import { useForceableQuery } from "./helpers";

export function useGetOrganizers() {
  const url = `${process.env.REACT_APP_API_URL}v1/organizers`;
  const key = ["organizers"];

  return useForceableQuery(key, url);
}

export function useGetOrganizer(orgKey, gameKey) {
  const url = gameKey
    ? `${process.env.REACT_APP_API_URL}v1/organizer/${orgKey}/game/${gameKey}`
    : `${process.env.REACT_APP_API_URL}v1/organizer/${orgKey}`;
  const key = ["organizers", orgKey];

  if (gameKey) {
    key.push(gameKey);
  }

  return useForceableQuery(key, url);
}
